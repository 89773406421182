<template lang="pug">
  include /mixins
  +b.tt-space_mt--5
    +b.order-table.tt-space_mb--2(v-for='item in list')
      +e.wrapper
        row
          cell(cols="narrow")
            +e.status-bar(
              :class="`order-table__status-bar--${[fieldData('status_id', item)]}`")
          cell(cols="auto")
            row(align="center" justify="between-md" appearance="spaced none-sm")
              cell(cols="6 2-md")
                +b.ds-caption--color_semigrey.--size_xs.tt-space_mb--1.P {{ _('Заказ')}} {{ getIdsList(item.props.orders) }} {{ _('от')}}  {{ fieldData('external_start_date', item) || fieldData('created_at', item) }}
                +b.ds-caption--bold.--size_sm.P {{ fieldData('status', item) }}
              cell(cols="12 3-sm")
                template(v-for='order in item.props.orders')
                  template(v-for='product in order.props.simplified_data.items')
                    +e.preview--xs.IMG(
                      :src="fieldData('img', product) || '/static/img/rebranding/non.png'"
                      :alt='fieldData("image_alt", product)'
                      :title='fieldData("image_alt", product)'
                    )
              cell(cols="narrow 2-sm")
                +b.ds-caption--color_semigrey.--size_xs.tt-space_mb--1.P {{_('Сумма заказа')}}
                +b.ds-caption.--size_sm.P #[span(v-html='getSplittedHtml(total(item))')]
              cell(cols="3-md 2-sm" )
                template(
                  v-for='payment in item.props.payments'
                )
                  template(v-if="!(payment.props.paid_url && payment.props.gateway.service == $options.NOVAPAY_PAYMENT_SERVICE && successStatus(payment.props.status))")
                    +b.ds-caption--color_semigrey.--size_xs.tt-space_mb--1.P {{_('Статус оплаты')}}
                      +b.ds-caption.--size_sm.P(
                      ) {{ pathOr(null, ['props', 'status_choices', 'display'], item.props.payments[0]) }}
              cell(cols="narrow 2-md" )
                template(
                  v-for='payment in item.props.payments'
                )
                  //- +b.tt-button--mini.--styling_default.ds-caption--size_xs.tt-space_mb--1.A(
                  //-   :href='payment.props.paid_url'
                  //-   target='_blank'
                  //-   v-if='payment.props.paid_url && (containStatus(payment.props.status))'
                  //- )
                  //-   +e.title.SPAN {{ _('Оплатить') }}
                  //- +b.tt-button--mini.--styling_default.ds-caption--size_xs.tt-space_mb--1.A(
                  //-   :href='payment.props.paid_url'
                  //-   target='_blank'
                  //-   v-if='payment.props.paid_url && payment.props.gateway.service == $options.NOVAPAY_PAYMENT_SERVICE && successStatus(payment.props.status)'
                  //- )
                  //-   +e.title.SPAN {{ _('Посмотреть квитанцию') }}
                  +b.tt-link--styling_default.ds-caption--size_xs.tt-space_mb--1.A(
                    v-for='text in payment.props.gateway.status_texts'
                    :href='payment.props.paid_url'
                    v-if='payment.props.paid_url && payment.props.status == text.props.status'
                    target='_blank'
                  ) {{ text.props.text }}
        //- //- slot(
        //-   :is-active="isOpen"
        //-   :export-id="tableData.props.uuid"
        //-   :orders="fieldData('orders_path')",
        //-   :payments='tableData.props.payments'
        //- )

</template>
<script>
import { pathOr } from 'ramda'
import {
  ordersList,
  // exportResource,
} from '@api/staff.service'

const lang = window.language

const WAITING_PAYMENT_STATUS = 'waiting_for_payment'
const PAID_PAYMENT_STATUS = 'paid'
const HOLD_PAYMENT_STATUS = 'hold'
const ERROR_PAYMENT_STATUS = 'error'

const NOVAPAY_PAYMENT_SERVICE = 'novapay'

const SHOW_PAYMENT_LINK_STATUSES = [WAITING_PAYMENT_STATUS, ERROR_PAYMENT_STATUS]
const SUCCESS_PAYMENT_STATUSES = [PAID_PAYMENT_STATUS, HOLD_PAYMENT_STATUS]

export default {
  name: 'order-state-loader',
  ordersList,
  PAID_PAYMENT_STATUS,
  NOVAPAY_PAYMENT_SERVICE,
  data() {
    return {
      state: null,
      parameters: {
        page: 1,
        page_size: 10,
        ordering: '-created_at',
        uuids: '',
      },
      list: [],
      fields: {
        id: ['id'],
        created_at: ['props', 'created_at'],
        external_start_date: ['props', 'external_start_date'],
        status: ['props', 'status', 'title'],
        status_id: ['props', 'status', 'order_status_group', 'value'],
        amount: ['props', 'payments', 0, 'props', 'amount'],
        payment_status: ['props', 'payment_status', 'display'],
        img: ['detail', 'img', 'catalog_preview'],
        image_alt: ['detail', 'product', 'title'],
        orders_path: ['props', 'orders'],
        orders: {
          waybill_code: ['props', 'waybill_code'],
          delivery_payment_type: ['props', 'shipping_service', 'props', 'price_description'],
          delivery_type_caption: ['props', 'shipping_service', 'caption'],
          delivery_warehouse: ['props', 'warehouse', 'caption'],
          delivery_street: ['props', 'street', 'props', 'description'],
          delivery_street_type: ['props', 'street', 'props', 'streets_type'],
          delivery_house: ['props', 'house'],
          delivery_building: ['props', 'building'],
          delivery_apartment: ['props', 'apartment'],
          delivery_city: ['props', 'city', 'props', lang === 'ru' ? 'description_ru' : 'description'],
          delivery_city_type: ['props', 'city', 'props', lang === 'ru' ? 'settlement_type_description_ru' : 'settlement_type_description'],
          warehouse_info: ['props', 'warehouse'],
          first_name: ['props', 'first_name'],
          last_name: ['props', 'last_name'],
          phone: ['props', 'phone'],
          email: ['props', 'email'],
          uuid: ['props', 'external_id'],
          number: ['props', 'order_number'],
          created_at: ['props', 'created_at'],
          external_start_date: ['props', 'external_start_date'],
          order_item_status: ['props', 'status', 'display'],
          shipping_payment_amount: ['props', 'shipping_service', 'props', 'title'],
          total: ['props', 'total_price'],
          currency: ['props', 'currency_label'],
          bonus_sum: ['props', 'bonus_sum'],
          simplified_data: {
            preview: ['detail', 'img', 'catalog_preview'],
            caption: ['detail', 'product', 'title'],
            is_active: ['detail', 'is_active'],
            weight: ['detail', 'weight', 'props', 'name'],
            price: ['price'],
            quantity: ['quantity'],
            discount_value: ['discount_value'],
            full_price: ['sum'],
            link: ['detail', 'url'],
            brand_name: ['brand_name'],
            item_name: ['item_name'],
          },
        },
      },
      lang: window.language,
    }
  },
  computed: {
    fieldData() {
      return function(val, item) {
        return pathOr(null, this.fields[val], item)
      }
    },
    total() {
      return function(val) {
        return val.props.orders.reduce((acc, x) => acc + x.props.total_price, 0)
      }
    },
  },
  created() {
    const state = new URLSearchParams(window.location.search).get('_state')
    this.state = JSON.parse(atob(state))
    this.parameters.uuids = this.state.join(',')
    console.log(this.state, this.parameters, '>>>>>>>>>>>');
    this.getList()
  },
  methods: {
    pathOr,
    successStatus(status) {
      return SUCCESS_PAYMENT_STATUSES.includes(status)
    },
    async getList() {
      const { data: { items } } = (await ordersList.execute({ filters: this.parameters }))
      this.list = items
    },
    getIdsList(orders) {
      return orders.filter(e => e.props.order_number).map(e => !!e.props.order_number && e.props.order_number).join(', ')
    },
    containStatus(status) {
      return SHOW_PAYMENT_LINK_STATUSES.includes(status)
    },
  },
}
</script>
